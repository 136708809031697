// Sidebar User-Menu Toggle
(function () {
  const button = document.querySelector('.user-dropdown__button');
  const menu   = document.querySelector('.user-dropdown__menu');

  if (!button) {
    return;
  }

  button.addEventListener('click', () => {
    menu.classList.toggle('active');
  });
}());

// Mobile-Menu Toggle
(function () {
  const button = document.querySelector('.header-menu__button');

  if (!button) {
    return;
  }

  button.addEventListener('click', () => {
    const mobileNavigation = document.querySelector('.mobile-navigation');

    mobileNavigation.classList.toggle('active');
  });
}());

// Mobile-Menu Touch Handler
(function () {
  const toggles = document.querySelectorAll('.mobile-navigation .nav-toggle');

  [].slice.call(toggles).forEach((element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      event.target.classList.toggle('active');
    });
  });
}());

// Home - Förderbeispiele
(function () {
  const sponsorship = document.querySelector('.section-sponsorship');

  if (!sponsorship) {
    return;
  }

  const list        = sponsorship.querySelector('.sponsorship-list');
  const items       = [].slice.call(list.querySelectorAll('.sponsorship-item'));
  const translate   = 100;
  let   index       = 0;

  const pagination = document.createElement('div');
  pagination.classList.add('pagination');

  const next = document.createElement('button');
  next.classList.add('button-next', 'btn', 'icon-arrow-next');
  next.addEventListener('click', (event) => {
    event.preventDefault();

    const listWidth = getComputedStyle(list).getPropertyValue('width').replace('px', '');
    const itemsWidth = getComputedStyle(items[0]).getPropertyValue('width').replace('px', '');
    const visibleItems = listWidth / itemsWidth;

    if (index < items.length - visibleItems) {
      index++;
      items.forEach((item) => {
        const position = -index * translate;
        item.style.transform = `translateX(${position}%)`;
      });
    }
  });

  const prev = document.createElement('button');
  prev.classList.add('button-previous', 'btn', 'icon-arrow-prev');
  prev.addEventListener('click', (event) => {
    event.preventDefault();
    if (index > 0) {
      index--;
      items.forEach((item) => {
        const position = -index * translate;
        item.style.transform = `translateX(${position}%)`;
      });
    }
  });

  pagination.appendChild(next);
  pagination.appendChild(prev);
  sponsorship.appendChild(pagination);
}());

// Center and crop thumbnails
(function () {
  const thumbnails = document.querySelectorAll('.thumbnail img');

  [].slice.call(thumbnails).forEach((element) => {
    const width = element.width;
    const height = element.height;

    element.style.width = 'unset';
    element.style.height = 'unset';

    if (width > height) {
      element.style.height = '100%';
    } else {
      element.style.width = '100%';
    }
  });
}());

// Cookie-Bar
(function () {
  const cookieName = 'cookie-bar';
  const cookieBar = document.querySelector('#cookie-bar');
  const button = document.querySelector('.cookie-bar__submit');

  // show cookie-bar if no cookie is set
  if (document.cookie.indexOf(cookieName) === -1) {
    cookieBar.style.display = 'block';
  }

  if (!button) {
    return;
  }

  button.addEventListener('click', () => {
    const now = new Date();
    const time = now.getTime();
    const expireTime = time + 1000 * (86400 * 30); // 30 days
    now.setTime(expireTime);
    // set cookie
    document.cookie = `${cookieName}=true; expires=${now.toGMTString()}; path=/`;

    // hide cookie-bar
    cookieBar.style.display = 'none';
  });
}());
